<template>
  <section class="regions">
    <div class="w-100 mt-3">
      <custom-filter @handleSearch="fetchList" @handleAdd="handleAdd"></custom-filter>
      <b-card>
        <b-table :items="datas" :fields="fields">
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button variant="primary" size="sm" class="ml-1" @click="handleEdit(data.item.id)" >
                <b-icon icon="pencil"></b-icon>
              </b-button>
              <b-button class="ml-1" variant="danger" size="sm" @click="handleDelete(data.item.id)" >
                <b-icon icon="trash"></b-icon>
              </b-button>
              <b-button variant="info" size="sm" class="ml-1"
                        @click="$router.push({name: 'VariantNominationGeneralQuestions',
                        params: {variantId: data.item.id, stageId: $route.params.stageId}})">
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end mt-3">
          <b-pagination
              v-model="pagination.page"
              hide-goto-end-buttons
              :total-rows="total"
              :per-page="pagination.pageSize"
              @input="fetchList()"
          />
        </div>
      </b-card>
    </div>
    <b-modal id="modal-lg" size="lg" hide-footer :title="modalTitle" v-model="modalVisible" >
      <Form :id="id" :stages="stages" @saved="fetchList" />
    </b-modal>
  </section>
</template>

<script>
import {getVariants, deleteVariant} from '@/api/variants';
import { listTemplate } from '@/utils/mixins/listTemplate'
import CustomFilter from '@/views/components/CustomFilter'
import Form from './form'
import { getStages } from '@/api/nomination'

const actions = {
  get: getVariants,
  delete: deleteVariant,
}

export default {
  name: 'index',
  components:{
    CustomFilter,
    Form,
  },
  mixins:[
    listTemplate,
  ],
  data() {
    return {
      actions,
      fields: [
        {key:'id', label: this.$t('ID') },
        {key:'name_uz', label: this.$t('message.VariantUz')},
        {key:'name_ru', label: this.$t('message.VariantRu')},
        {key:'name_en', label: this.$t('message.VariantEn')},
        {key:'action', label: this.$t('message.Action')},
      ],
      stages: [],
      filters: {
        stage_id: this.$route.params.stageId
      }
    }
  },
  created() {
    this.fetchStages()
  },
  methods: {
    fetchStages() {
      getStages().then(res => {
        this.stages = res.data.data
      })
    }
  }
}
</script>

<style scoped>

</style>
