<template>
  <section class="category-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
<!--      <div class="w-100">-->
<!--        <b-form-group :label="$t('message.Stage')">-->
<!--          <validation-provider-->
<!--              #default="{ errors }"-->
<!--              :name="$t('message.Stage')"-->
<!--              rules="required"-->
<!--          >-->
<!--            <v-select :options="stages" label="stage_name" v-model="form.stage_id"></v-select>-->
<!--            <small class="text-danger">{{ errors[0] }}</small>-->
<!--          </validation-provider>-->
<!--        </b-form-group>-->
<!--      </div>-->
      <div class="w-100">
        <b-form-group :label="$t('message.VariantUz')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.ExperienceUz')"
              rules="required"
          >
            <b-input v-model="form.name_uz" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.VariantRu')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.ExperienceRu')"
              rules="required"
          >
            <b-input v-model="form.name_ru" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.VariantEn')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.ExperienceEn')"
              rules="required"
          >
            <b-input v-model="form.name_en" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('message.Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { storeVariant, updateVariant, showVariant } from '@/api/variants'
import { formTemplate } from '@/utils/mixins/formTemplate';
import { quillEditor }  from 'vue-quill-editor';
import vSelect from 'vue-select';

function initForm(data) {
  const get = (key, value = null) => _.get(data,key,value);

  return {
    id: get('id', null),
    stage_id: get('stage_id', null),
    name_uz: get('name_uz', null),
    name_ru: get('name_ru', null),
    name_en: get('name_en', null),
  }
}

const actions = {
  add:storeVariant,
  update: updateVariant,
  show: showVariant,
}

export default {
  name: 'Form',
  components:{
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  props: {
    stages: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      allCategories: [],
      allTypes: [],
    }
  },
  methods: {
    reformatData() {
      // if (this.form.stage_id) {
      //   this.form.stage_id = this.form.stage_id.id;
      // }
      this.form.stage_id = this.$route.params.stageId
      let form = this.prepareFormData(this.form);
      return form;
    },
    setForm(data) {
      this.form = initForm(data);
      // if(data) {
      //   let a = this.stages.filter(item => item.id === data.stage_id)
      //   this.form.stage_id = a[0]
      // }
    },
  }
}
</script>

<style scoped>

</style>
